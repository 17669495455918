<!--业务办理情况-->
<template>
    <widget-framework v-if="dataLoaded" title="业务办理情况" :show-border="false">
        <template #content>
            <div class="processInfo">
                <div class="queryParams">
                    <el-select clearable v-model="params.elecType" style="width: 100px" placeholder="行业分类" @change="paramChange">
                        <el-option v-for="item in keyValues.elecType"
                                   :key="parseInt(item.itemKey)"
                                   :value="parseInt(item.itemKey)"
                                   :label="item.itemValue">
                        </el-option>
                    </el-select>
                    <el-select clearable v-model="params.voltage" style="width: 100px" placeholder="供电电压" @change="paramChange">
                        <el-option v-for="item in keyValues.voltage"
                                   :label="item.itemValue"
                                   :value="parseInt(item.itemKey)"
                                   :key="parseInt(item.itemKey)">
                        </el-option>
                    </el-select>
                    <el-select clearable v-model="params.elecNature" style="width: 100px" placeholder="用电性质" @change="paramChange">
                        <el-option v-for="item in keyValues.elecNature"
                                   :label="item.itemValue"
                                   :value="parseInt(item.itemKey)"
                                   :key="parseInt(item.itemKey)">
                        </el-option>
                    </el-select>
                    <el-select clearable v-model="params.loadLevel" style="width: 100px" placeholder="负荷级别" @change="paramChange">
                        <el-option v-for="item in keyValues.loadLevel"
                                   :label="item.itemValue"
                                   :value="parseInt(item.itemKey)"
                                   :key="parseInt(item.itemKey)">
                        </el-option>
                    </el-select>
                    <el-date-picker
                        v-model="daterange"
                        type="daterange"
                        range-separator="~"
                        start-placeholder="时间筛选"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 200px"
                        @change="paramChange"
                    ></el-date-picker>
                </div>
                <div class="items">
                    <process-info-item
                        v-for="(item, index) in processInfos"
                        :processInfo="item"
                        :key="index"
                    ></process-info-item>
                </div>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import ProcessInfoItem from './ProcessInfoItem';
import dayjs from 'dayjs';

export default {
    name: 'BusinessProcessInfo',
    components: {
        WidgetFramework,
        ProcessInfoItem,
    },
    data() {
        return {
            dataLoaded: false,
            processInfos: [],
            params: {
                processDefinitionKey: 'flow_business_apply',
                size: 9,
                elecType: '',
                voltage: '',
                elecNature: '',
                loadLevel: '',
                startTime: '',
                endTime: '',
            },
            keyValues: {
                elecType: [],
                voltage: [],
                elecNature: [],
                loadLevel: [],

            }
        };
    },
    computed: {
        daterange: {
            get() {
                return [this.params.startTime, this.params.endTime];
            },
            set(times) {
                const [startTime, endTime] = times || [];
                this.params.startTime = startTime || '';
                this.params.endTime = endTime && dayjs(endTime).endOf('date').format('YYYY-MM-DD HH:mm:ss') || '';
            },
        },
    },
    methods: {
        paramChange() {
            this.getBusinessApplyProgress();
        },
        getBusinessApplyProgress() {
            this.$client.getBusinessApplyProgress(this.params).then((ret) => {
                this.processInfos = ret.data || [];
                this.dataLoaded = true;
            });
        },
        async bindKeyValues() {
            const dictMap = [
                {parentCode: 'voltage', listName: 'voltage'},
                {parentCode: 'elec_nature', listName: 'elecNature'},
                {parentCode: 'load_level', listName: 'loadLevel'},
                {parentCode: 'elec_type', listName: 'elecType'},
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this.keyValues[dict.listName] = data;
                }
            });
        },
        async initFormValue() {
            await this.bindKeyValues();
        },
    },
    mounted() {
        this.initFormValue();
        this.getBusinessApplyProgress();
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.processInfo {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column;

    .queryParams {
        display: flex;
        justify-content: flex-start;
        padding: 0 10px;

        & > div{
            margin: 0 5px;
        }

        /deep/ .el-input__inner {
            border: 1px solid @lightBlue;
            background-color: rgba(0, 204, 255, 0.2);
            color: @cyan;

            input {
                background-color: unset;
                color: @cyan;
            }
        }
    }

    .items {
        display: flex;
        flex-flow: row wrap;
    }
}

</style>
